import React from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.scss";

export const Portal = React.memo<{ children: React.ReactNode, containerId: string }>(({ children, containerId }) => {
  const divElement = React.useMemo(() => {
    const htmlDivElement = document.createElement("div");
    htmlDivElement.classList.add(styles.container);
    return htmlDivElement;
  }, []);

  React.useEffect(() => {
    const containerNode = document.getElementById(containerId)!;

    containerNode.appendChild(divElement);

    return () => {
      containerNode.removeChild(divElement);
    };
  }, [ divElement, containerId ]);

  return ReactDOM.createPortal(children, divElement);
});

export default Portal;
