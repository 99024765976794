import React from "react";

type PropsComparator<C extends React.ComponentType> = (
  prevProps: Readonly<React.ComponentProps<C>>,
  nextProps: Readonly<React.ComponentProps<C>>,
) => boolean;

export function genericMemo<C extends React.ComponentType<any>>(
  Component: C,
  propsComparator?: PropsComparator<C>,
) {
  return (React.memo(Component, propsComparator) as any) as C;
}
