import * as yup from "yup";
import { ErrorMessages } from "../../../util";
import { TreatmentType } from "../../../types";
import { DateSchema } from "yup";

export const validationSchema = yup.object({
  fullName: yup.string()
    .required(ErrorMessages.requiredField),
  birthday: yup.date()
    .nullable()
    .max(new Date(), ErrorMessages.dateInFutureField)
    .required(ErrorMessages.requiredField),
  phone: yup.string()
    .required(ErrorMessages.requiredField),
  gender: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  termsOfServiceAgreement: yup.boolean()
    .isTrue(ErrorMessages.requiredField)
    .required(ErrorMessages.requiredField),
  informationCorrect: yup.boolean()
    .isTrue(ErrorMessages.requiredField)
    .required(ErrorMessages.requiredField),
  email: yup.string()
    .nullable()
    .email(ErrorMessages.emailField),
  treatmentDate: yup.date()
    .nullable()
    .when([ "treatmentType" ], {
      is: (treatmentType: TreatmentType) => treatmentType === "PreferredDate",
      then: (schema: any) =>
        (schema as DateSchema)
          .min(new Date(), ErrorMessages.dateInPastField)
          .required(ErrorMessages.requiredField),
    }),
  treatmentTime: yup.date()
    .nullable()
    .when([ "treatmentType" ], {
      is: (treatmentType: TreatmentType) => treatmentType === "PreferredDate",
      then: (schema: any) =>
        (schema as DateSchema)
          .required(ErrorMessages.requiredField),
    }),
  street: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  city: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  state: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  zip: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  positiveCovid: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
  testedCovid: yup.string()
    .nullable()
    .required(ErrorMessages.requiredField),
});
