import React from "react";
import { Button, Modal } from "@material-ui/core";
import styles from "./style.module.scss";
import MimicFormik from "../../generic/form/MimicFormik";
import * as RegisterForm from "../RegisterForm";
import { RegisterFormFields } from "../../../types";
import { FormikHelpers } from "formik/dist/types";
import { register } from "../../../api";
import DateFnsUtils from "@date-io/date-fns";

type RegisterModalProps = { isOpen: boolean, onRequestClose: () => void };

export const RegisterModal = React.memo<RegisterModalProps>((
  {
    isOpen = true, onRequestClose,
  },
) => {
  const onSubmit = React.useCallback(async (values: RegisterFormFields, formikHelpers: FormikHelpers<RegisterFormFields>) => {
    const dateFnsUtils = new DateFnsUtils();

    const response = await register({
      ...values,
      birthday: values?.birthday ? dateFnsUtils.format(values.birthday, "MM/dd/yyyy") : null,
      treatmentDate: values?.treatmentDate ? dateFnsUtils.format(values.treatmentDate, "MM/dd/yyyy") : null,
      treatmentTime: values?.treatmentTime ? dateFnsUtils.format(values.treatmentTime, "HH:mm") : null,
      covidSymptoms: values?.covidSymptoms?.join(", ")
    });

    if (response.data.success) {
      formikHelpers.resetForm();
      onRequestClose();
    }
  }, [ onRequestClose ]);

  return (
    <MimicFormik
      initialValues={RegisterForm.initialValues}
      validationSchema={RegisterForm.validationSchema}
      onSubmit={onSubmit}
    >
      {
        props =>
          <Modal
            open={isOpen}
            onClose={onRequestClose}
          >
            <div className={styles.form}>
              <RegisterForm.Form renderHiddenSubmitButton={false}>
                <div className={styles.buttons}>
                  <Button
                    type="button"
                    disabled={props.isSubmitting || props.isValidating}
                    onClick={() => onRequestClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={props.isSubmitting || props.isValidating}
                  >
                    Submit
                  </Button>
                </div>
              </RegisterForm.Form>
            </div>
          </Modal>
      }
    </MimicFormik>
  );
});

export default RegisterModal;
