import { RegisterFormFields } from "../../../types";

export const initialValues: RegisterFormFields = {
  fullName: "",
  termsOfServiceAgreement: false,
  birthday: null,
  email: null,
  treatmentType: "ASAP",
  gender: "",
  phone: "",
  treatmentDate: null,
  treatmentTime: null,
  street: "",
  city: "",
  state: "",
  zip: "",
  carrierName: null,
  groupNumber: null,
  memberId: null,
  insurancePhoneNumber: null,
};
