import React from "react";
import { FormControl, FormControlLabel, FormHelperText, Checkbox as BaseCheckbox } from "@material-ui/core";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel/FormControlLabel";

export const Checkbox = React.memo<Omit<FormControlLabelProps, "control"> & {
  error?: boolean; helperText?: string, value?: boolean
}>(({ error, className, helperText, value, ...props }) => {
  return (
    <FormControl error={error} className={className}>
      <FormControlLabel
        control={<BaseCheckbox color="primary" />}
        checked={value || false}
        {...props}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});
export default Checkbox;
