import React, { CSSProperties } from "react";
import { Form, FormikFormProps } from "formik";
import { DisabledContextProvider } from "../DisabledContext";

export type MimicFormikFormProps = {
  renderHiddenSubmitButton?: boolean
  disabled?: boolean
} & FormikFormProps;

type FormProps = React.ComponentPropsWithoutRef<"form">;

const hiddenButtonStyle: CSSProperties = {
  width: 0,
  height: 0,
  opacity: 0,
  visibility: "hidden",
};

export const MimicForm = React.memo<MimicFormikFormProps>(React.forwardRef<HTMLFormElement, FormProps>(
  ((
    {
      children,
      renderHiddenSubmitButton,
      disabled,
      ...props
    }: MimicFormikFormProps, ref) => {
    return (
      <DisabledContextProvider value={disabled}>
        <Form
          ref={ref}
          {...props}>
          {children}
          {
            /*hidden button for submitting form on ENTER button*/
            !!renderHiddenSubmitButton && <button style={hiddenButtonStyle} type="submit" />
          }
        </Form>
      </DisabledContextProvider>
    );
  }),
));


(MimicForm as React.ComponentType<MimicFormikFormProps>).defaultProps = {
  renderHiddenSubmitButton: true,
};


export default MimicForm;
