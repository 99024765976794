import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RegisterModal from "../components/Register/RegisterModal";
import Portal from "../components/Portal/Portal";

export const App = () => {
  const [ isOpen, setIsOpen ] = React.useState(false);


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Portal containerId="header-menu">
        <button className="header-menu_item contact-us" onClick={() => setIsOpen(true)}>Book appointment</button>
      </Portal>

      <RegisterModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
    </MuiPickersUtilsProvider>
  );
};

export default App;
