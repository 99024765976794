import React from "react";

export const DisabledContext = React.createContext<boolean>(false);

export const DisabledContextProvider = React.memo<{ value?: boolean | null, children: React.ReactNode }>(
  (
    {
      value,
      children,
    },
  ) =>
    <DisabledContext.Consumer>
      {
        externalDisabled =>
          <DisabledContext.Provider value={externalDisabled || value || false}>
            {children}
          </DisabledContext.Provider>
      }
    </DisabledContext.Consumer>,
);

export default DisabledContext;
