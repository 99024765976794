import React from "react";
import { FieldInputProps, FormikProps, GenericFieldHTMLAttributes } from "formik/dist/types";
import { Field, FieldConfig, FieldProps } from "formik";
import { genericMemo } from "../../../util";
import DisabledContext from "../DisabledContext";

export type MimicFieldConfig<V = any, P = any> = {
  component: string | React.FunctionComponent<P> | React.ComponentClass<P, any>,
  onChangeMimic?: (value: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void,
  formatMimic?: (value: any, name: string) => any,
  normalizeMimic?: (value: any, previousValue: any, allValues: any, name: string) => any;
  useSecondArgument?: boolean;
} & Omit<P, "value" | "onChange"> & GenericFieldHTMLAttributes & Omit<FieldConfig<V>, "as" | "component"> & V & {
  name: string;
};

export const MimicField = genericMemo(<V extends object, P>(
  {
    component,
    onChangeMimic,
    formatMimic,
    normalizeMimic,
    useSecondArgument,
    ...props
  }: MimicFieldConfig<V, P>) => {

  const formRef = React.useRef<FormikProps<any>>();

  const fieldRef = React.useRef<FieldInputProps<V>>();

  const wrappedComponent = React.useMemo(() => React.memo<FieldProps<V> & { disabled?: boolean }>((
    {
      form,
      field,
      disabled,
      ...props
    }) => {

    const disabledByContext = React.useContext(DisabledContext);

    formRef.current = form;
    fieldRef.current = field;

    const onChange = React.useCallback((v: any, e: any) => {
      if (useSecondArgument) {
        v = e;
      } else {
        if (v?.hasOwnProperty("target")) {
          if (v.target.hasOwnProperty("value")) {
            v = v?.target?.value;
          } else if (v.target.hasOwnProperty("checked")) {
            v = v?.target?.checked;
          }
        }
      }

      const value = normalizeMimic
        ? normalizeMimic(v, fieldRef.current!.value, formRef.current!.values, fieldRef.current!.name)
        : v;
      formRef.current!.setFieldValue(fieldRef.current!.name, value);
      onChangeMimic?.(value, formRef.current!.setFieldValue);
    }, []);

    const onBlur = React.useCallback((v: any) => {
      fieldRef.current!.onBlur(v);
    }, []);

    const memoComponent = React.useMemo(() => {
      if (component) {
        return React.memo(component as any);
      }
    }, []);

    if (memoComponent) {
      const fieldMeta = form.getFieldMeta(field.name);
      const shouldErrorBeShown = form.submitCount > 0 || fieldMeta.touched;

      return React.createElement(memoComponent, {
        value: formatMimic ? formatMimic(field.value, field.name) : field.value,
        onChange: onChange,
        onBlur: onBlur,
        error: shouldErrorBeShown && Boolean(fieldMeta.error),
        disabled: disabled || disabledByContext || form.isSubmitting,
        id: field.name,
        name: field.name,
        ...props,
        helperText: shouldErrorBeShown ? fieldMeta.error : (props as any).helperText,
      } as any);
    }
    return null;
  }), [ component, formatMimic, normalizeMimic, onChangeMimic, useSecondArgument ]);

  return (
    <Field {...props} component={wrappedComponent} />
  );
});

export default MimicField;
