import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/style.scss";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);


(() => {
  window.onscroll = function () {
    myFunction();
  };

  const w = window.innerWidth;
  const header = document.getElementById("header")!;

  function myFunction() {
    const sticky = header.offsetTop;

    if (window.pageYOffset > sticky && w > 600) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  const mobileMenu = document.getElementsByClassName("header_menu-btn");

  if (mobileMenu[0]) {
    mobileMenu[0].addEventListener("click", () => {
      header.classList.toggle("menuOpen");
    });
  }

  document.body.addEventListener("click", (e) => {
    let p = e.target as any;
    while (p) {
      if (p.className) {
        if (p.className === "header-menu menuOpen") {
          return;
        }
      }
      p = p.parentElement;
    }
    header.classList.remove("menuOpen");
  });
})();
