import React from "react";
import MimicForm, { MimicFormikFormProps } from "../../generic/form/MimicForm";
import MimicField from "../../generic/form/MimicField";
import { TextField, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import Checkbox from "../../generic/fields/CheckBox";
import Select from "../../generic/fields/Select";
import PhoneNumber from "material-ui-phone-number";
import { useFormikContext } from "formik";
import { RegisterFormFields } from "../../../types";
import styles from "./style.module.scss";

const states = [
  "Alaska", "Alabama", "Arkansas", "Arizona", "California", "Colorado", "Connecticut", "District of Columbia",
  "Delaware", "Florida", "Georgia", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky",
  "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana",
  "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Virginia", "Vermont", "Washington", "Wisconsin", "West Virginia",
];

const genders = [ "Male", "Female" ];

const symptoms = [
  "Fever or chills", "Cough", "Shortness of breath or difficulty breathing", "Fatigue",
  "Muscle or body aches", "Headache", "New loss of taste or smell",
  "Sore throat", "Congestion or runny nose", "Nausea or vomiting", "Diarrhea" ];

const booleanOptions = [ "Yes", "No" ];

export const Form = React.memo<MimicFormikFormProps>(({ children, ...props }) => {

  const { values, setFieldValue, isSubmitting } = useFormikContext<RegisterFormFields>();

  React.useEffect(() => {
    if (values.treatmentType === "ASAP") {
      setFieldValue("treatmentDate", null);
      setFieldValue("treatmentTime", null);
    } else if (values.treatmentType === "PreferredDate") {
      setFieldValue("treatmentDate", null);
      setFieldValue("treatmentTime", null);
    }
  }, [ values.treatmentType, setFieldValue ]);

  return (
    <MimicForm {...props} className={styles.form}>

      <h4 className={styles.block}>
        Personal Information
      </h4>

      <MimicField
        name="fullName"
        label="Full name"
        component={TextField}
      />
      <MimicField
        name="birthday"
        label="Birthday"
        format="MM/dd/yyyy"
        component={KeyboardDatePicker}
      />
      <MimicField
        name="phone"
        label="Phone number"
        defaultCountry="us"
        component={PhoneNumber}
      />
      <MimicField
        name="gender"
        label="Gender"
        options={genders}
        component={Select}
        useSecondArgument
      />

      <MimicField
        name="email"
        label="Email"
        component={TextField}
      />


      <h4 className={styles.block}>
        Date and Time of Treatment
      </h4>
      <FormControl component="fieldset">
        <MimicField
          name="treatmentType"
          component={RadioGroup}
          useSecondArgument
        >
          <FormControlLabel
            disabled={isSubmitting}
            value="ASAP"
            control={<Radio color="primary" />}
            label="ASAP"
          />
          <FormControlLabel
            disabled={isSubmitting}
            value="PreferredDate"
            control={<Radio color="primary" />}
            label="Preferred Date"
          />
        </MimicField>
      </FormControl>
      {
        values.treatmentType === "PreferredDate" &&
        <>
          <MimicField
            name="treatmentDate"
            label="Treatment date"
            format="MM/dd/yyyy"
            component={KeyboardDatePicker}
          />
          <MimicField
            name="treatmentTime"
            label="Treatment time"
            component={KeyboardTimePicker}
          />
        </>
      }


      <h4 className={styles.block}>
        Address For Treatment
      </h4>
      <MimicField
        name="street"
        label="Street Address"
        component={TextField}
      />
      <MimicField
        name="city"
        label="City"
        component={TextField}
      />
      <MimicField
        name="state"
        label="State"
        options={states}
        component={Select}
        useSecondArgument
      />
      <MimicField
        name="zip"
        label="ZIP"
        component={TextField}
      />


      <h4 className={styles.block}>
        Insurance
      </h4>
      <MimicField
        name="carrierName"
        label="Carrier name"
        component={TextField}
      />
      <MimicField
        name="groupNumber"
        label="Group #"
        component={TextField}
      />
      <MimicField
        name="memberId"
        label="Member Id #"
        component={TextField}
      />
      <MimicField
        name="insurancePhoneNumber"
        label="Insurance phone number"
        defaultCountry="us"
        component={PhoneNumber}
      />

      <h4 className={styles.block}>
        COVID-19 exposure
      </h4>
      <MimicField
        className={styles.covid}
        name="covidSymptoms"
        label="Are you experiencing symptoms of COVID-19?"
        options={symptoms}
        component={Select}
        multiple
        useSecondArgument
      />
      <MimicField
        className={styles.covidBooleanSelect}
        name="positiveCovid"
        label="Were you exposed to a Positive COVID-19 patient?"
        options={booleanOptions}
        component={Select}
        useSecondArgument
      />
      <MimicField
        className={styles.covidBooleanSelect}
        name="testedCovid"
        label="Have you been tested positive for COVID-19?"
        options={booleanOptions}
        component={Select}
        useSecondArgument
      />

      <MimicField
        className={styles.agreement}
        name="termsOfServiceAgreement"
        label="I Agree to the “Terms of Service”"
        component={Checkbox}
        useSecondArgument
      />

      <MimicField
        className={styles.agreement}
        name="informationCorrect"
        label="I acknowledge that information put in the form is correct"
        component={Checkbox}
        useSecondArgument
      />

      {children}
    </MimicForm>
  );
});


export default Form;
