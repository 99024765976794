import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete/Autocomplete";

export const Select = React.memo<Omit<AutocompleteProps<any, any, any, any>,'renderInput'> & { helperText?: string, error?: boolean, label?: string, options: any[] }>((
  {
    error,
    options,
    helperText,
    label,
    id,
    ...props
  }) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={(option: any) => option}
      renderInput={(params: any) => <TextField {...params} label={label} helperText={helperText} error={error} />}
      {...props}
    />
  );
});

export default Select;
